import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorModal from "../../Add business/ErrorModal";
import SuccessModal from "../../Add business/SuccessModal";
import useBusinessQuery from "../../../../../store/Bussiness/useBusinessQuery";
import { FaSave } from "react-icons/fa";
import { Box, Button, Typography } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import ImageRemovalModal from "../../Add business/ImageRemovalModal";
import axios from "axios";
import UploadModal from "../../../../../components/UploadModal";
import S3 from "react-aws-s3";
import { Buffer } from "buffer";
window.Buffer = Buffer;

function EditShopImage() {
  const { id } = useParams();
  const history = useHistory();

  const [shopImage, setShopImage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openSucces, setOpensuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [plan, setPlan] = useState({});
  const [uploadModal, setUploadModal] = useState(false);

  const envConfig = process.env;

  const config = {
    bucketName: envConfig.REACT_APP_S3_BUCKET,
    region: envConfig.REACT_APP_S3_REGION,
    dirName: "master-click",
    accessKeyId: envConfig.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: envConfig.REACT_APP_S3_SECRET_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);

  const { data: subscriptionDetail, isSuccess: subscriptionSuccess } =
    useBusinessQuery.Get_my_currentplan(id);

  const { data: businessDetails, isSuccess: businessSuccess } =
    useBusinessQuery.Get_my_bussiness_details(id);

  const {
    mutateAsync: EditBusiness,
    isSuccess: editSuccess,
    isError: editError,
  } = useBusinessQuery.Edit_business();

  useEffect(() => {
    if ((businessSuccess, businessDetails)) {
      setShopImage(businessDetails.data.image);
    }
  }, [businessSuccess, businessDetails]);

  const handleCloseUpload = () => {
    setUploadModal(false);
  };

  

  useEffect(() => {
    if (subscriptionSuccess || subscriptionDetail) {
      setPlan(subscriptionDetail?.data?.selected_plan?.subscription_features);
    }
  }, [subscriptionSuccess, subscriptionDetail]);

  const handleClose = async (value) => {
    if (value) {
      setShopImage("");
    }

    setOpenModal(false);
  };

  const handleSuccessModal = () => {
    if (plan.cover_images) {
      history.push(`/edit-coverimages/${id}`);
    } else if (plan.catalogue_images) {
      history.push(`/edit-catelogueimages/${id}`);
    } else {
      history.push("/my-list");
    }

    setOpensuccess(false);
  };

  const handleErrorModal = () => {
    setOpenError(false);
  };

  const handlePosterImage = async (e) => {
    try {
      let file = null;
      
      // If we have an event with files (from a standard input)
      if (e && e.target && e.target.files) {
        if (e.target.files.length === 0) {
          return;
        }
        file = e.target.files[0];
      }
      // Otherwise use either Flutter channel or create a file input
      else {
        // Flutter file channel approach
        if (window.FileChannel) {
          // Set up a callback to receive file data
          file = await new Promise((resolve, reject) => {
            // This part still needs a Promise since we're setting up a callback
            window.fileCallback = (fileData) => {
              console.log('Raw fileData received:', fileData);
              
              // Handle different possible data formats
              try {
                // Case 1: If fileData is an object with structured properties
                if (typeof fileData === 'object' && fileData !== null) {
                  const { fileName, fileBase64, fileSize, fileType } = fileData;
                  console.log('Structured data detected:', { fileName, fileSize, fileType });
                  
                  if (fileBase64) {
                    // Process base64 data
                    let base64Content = fileBase64;
                    if (fileBase64.includes('base64,')) {
                      base64Content = fileBase64.split('base64,')[1];
                    }
                    
                    // Create blob and file
                    const byteCharacters = atob(base64Content);
                    const byteArrays = [];
                    
                    for (let i = 0; i < byteCharacters.length; i += 1024) {
                      const slice = byteCharacters.slice(i, i + 1024);
                      const byteNumbers = new Uint8Array(slice.length);
                      
                      for (let j = 0; j < slice.length; j++) {
                        byteNumbers[j] = slice.charCodeAt(j);
                      }
                      
                      byteArrays.push(byteNumbers);
                    }
                    
                    const blob = new Blob(byteArrays, { type: fileType || 'image/jpeg' });
                    const fileObject = new File([blob], fileName || `image_${Date.now()}.jpg`, { type: fileType || 'image/jpeg' });
                    
                    console.log('Created File object from structured data');
                    return resolve(fileObject);
                  }
                }
                
                // Case 2: If fileData is a string containing base64 data
                if (typeof fileData === 'string') {
                  console.log('String data detected, length:', fileData.length);
                  
                  // If it's base64 format
                  if (fileData.includes('base64')) {
                    const matches = fileData.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
                    
                    if (matches && matches.length === 3) {
                      const mimeType = matches[1];
                      const base64Data = matches[2];
                      
                      // Process base64 data
                      const byteCharacters = atob(base64Data);
                      const byteArrays = [];
                      
                      for (let i = 0; i < byteCharacters.length; i += 1024) {
                        const slice = byteCharacters.slice(i, i + 1024);
                        const byteNumbers = new Uint8Array(slice.length);
                        
                        for (let j = 0; j < slice.length; j++) {
                          byteNumbers[j] = slice.charCodeAt(j);
                        }
                        
                        byteArrays.push(byteNumbers);
                      }
                      
                      const blob = new Blob(byteArrays, { type: mimeType });
                      const fileObject = new File([blob], `image_${Date.now()}.${mimeType.split('/')[1] || 'jpg'}`, { type: mimeType });
                      
                      console.log('Created File object from base64 string');
                      return resolve(fileObject);
                    }
                  }
                  
                  // If it's comma-separated format
                  const parts = fileData.split(',');
                  if (parts.length >= 3) {
                    const [path, name, size] = parts;
                    console.log('Comma-separated data detected:', { path, name, size });
                    // Here we don't have actual file content, just returning the raw data
                    return resolve(fileData);
                  }
                  
                  // If it's just raw base64 without prefix
                  try {
                    // Assume it might be raw base64
                    const byteCharacters = atob(fileData);
                    const byteArrays = [];
                    
                    for (let i = 0; i < byteCharacters.length; i += 1024) {
                      const slice = byteCharacters.slice(i, i + 1024);
                      const byteNumbers = new Uint8Array(slice.length);
                      
                      for (let j = 0; j < slice.length; j++) {
                        byteNumbers[j] = slice.charCodeAt(j);
                      }
                      
                      byteArrays.push(byteNumbers);
                    }
                    
                    const blob = new Blob(byteArrays, { type: 'image/jpeg' });
                    const fileObject = new File([blob], `image_${Date.now()}.jpg`, { type: 'image/jpeg' });
                    
                    console.log('Created File object from raw base64');
                    return resolve(fileObject);
                  } catch (e) {
                    console.log('Not valid base64 data');
                  }
                }
                
                // Fall back to just returning whatever we got
                console.log('Using raw data as fallback');
                return resolve(fileData);
              } catch (error) {
                console.error('Error processing file data:', error);
                return reject(error);
              }
            };
            
            console.log('Requesting file from Flutter...');
            try {
              window.FileChannel.postMessage('chooseFile');
            } catch (err) {
              console.error('Error calling FileChannel:', err);
              reject(err);
            }
          });
        }
        // Fallback to standard file input approach
        else {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = 'image/*';
          
          // We need to wrap this in a Promise because file selection is event-based
          file = await new Promise((resolve, reject) => {
            input.onchange = (event) => {
              if (event.target.files && event.target.files.length > 0) {
                const selectedFile = event.target.files[0];
                console.log('Selected file:', selectedFile);
                resolve(selectedFile);
              } else {
                reject(new Error('No file selected'));
              }
            };
            
            input.click();
          });
        }
      }
      
      if (file) {
        setUploadModal(true);
        const data = await ReactS3Client.uploadFile(file);
        const url = data.location;
        setShopImage(url);
        handleCloseUpload();
      }
    } catch (error) {
      console.error('Error handling photo:', error);
      // You might want to set an error state or notify the user
      handleCloseUpload(); // Close the modal even on error
    }
  }

  const handleAdd = async () => {
    try {
      let payload = { id, image: shopImage };
      if (businessDetails.data.name) {
        payload.name = businessDetails.data.name;
      }
      if (businessDetails.data.mobile_number) {
        payload.mobile_number = businessDetails.data.mobile_number;
      }
      if (businessDetails.data.type) {
        payload.type = businessDetails.data.type;
      }
      if (businessDetails.data.category) {
        payload.category = businessDetails.data.category;
      }
      if (businessDetails.data.country) {
        payload.country = businessDetails.data.country;
      }
      if (businessDetails.data.state) {
        payload.state = businessDetails.data.state;
      }
      if (businessDetails.data.district) {
        payload.district = businessDetails.data.district;
      }

      if (businessDetails.data.about_us) {
        payload.about_us = businessDetails.data.about_us;
      }
      if (businessDetails.data.post_office) {
        payload.post_office = businessDetails.data.post_office;
      }
      if (businessDetails.data.pin_code) {
        payload.pin_code = businessDetails.data.pin_code;
      }
      if (businessDetails.data.longitude) {
        payload.longitude = businessDetails.data.longitude;
      }
      if (businessDetails.data.latitude) {
        payload.latitude = businessDetails.data.latitude;
      }
      if (businessDetails.data.sub_category_id) {
        payload.sub_category = businessDetails.data.sub_category_id;
      }
      if (businessDetails.data.google_location_link) {
        payload.google_location_link =
          businessDetails.data.google_location_link;
      }
      if (businessDetails.data.whatsapp_number) {
        payload.whatsapp_number = businessDetails.data.whatsapp_number;
      }
      if (businessDetails.data.facebook) {
        payload.facebook = businessDetails.data.facebook;
      }
      if (businessDetails.data.instagram) {
        payload.instagram = businessDetails.data.instagram;
      }
      if (businessDetails.data.youtube) {
        payload.youtube = businessDetails.data.youtube;
      }

      if (businessDetails.data.website) {
        payload.website = businessDetails.data.website;
      }
      if (businessDetails.data.banner_image) {
        payload.banner_image = businessDetails.data.banner_image;
      }

      await EditBusiness(payload);
    } catch (error) { }
  };

  useEffect(() => {
    if (editSuccess) {
      setOpensuccess(true);
    }
    if (editError) {
      setOpenError(true);
    }
  }, [editSuccess, editError]);

  return (
    <Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
          position: "fixed",
          top: 0,
          zIndex: "100",
        }}
      >
        <ArrowBackIcon
          style={{
            color: "black",
          }}
          onClick={() => history.push("/")}
        />
        <p style={{ color: "black" }}>Edit Shop Image</p>
      </div>

      <div
        style={{
          marginTop: "70px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {shopImage ? (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              top: "20px",
            }}
          >
            <img
              src={shopImage}
              width={"92%"}
              height={"224px"}
              style={{
                borderRadius: "20px",
                border: "4px solid rgba(22, 107, 142, 1)",
                marginBottom: "10px",
              }}
              alt="No Image"
            />
            <Button
              className="delete-btn"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <RiDeleteBin5Line />
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      {!shopImage ? (
        <div
          style={{
            marginTop: "5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          onClick={handlePosterImage}
        >
          {/* <input
            style={{
              display: "none",
            }}
            type="file"
            id="file"
            accept=".jpg,.jpeg,.png"
            
          /> */}
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "#677eff",
              width: "92%",
              height: "3rem",
              border: "none",
              color: "info",
              backgroundColor: "#fff",
              border: "solid 3px #166B8E",
              borderRadius: "35px",
              padding: "8rem",
            }}
            htmlFor="file"
          >
            <i className=" fs-3 fa-solid fa-image"></i>
            <span
              style={{
                width: "100%",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src="/images/img-icon.png" />
              <p style={{ color: "#2182B1" }}>Add shop image</p>
            </span>
          </label>
        </div>
      ) : (
        ""
      )}

      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <Typography
          sx={{
            color: "#FF0000",
            fontSize: "12px !important",
            fontWeight: "600",
          }}
        >
          Note
        </Typography>
        <Typography
          sx={{
            color: "#000000",
            fontSize: "12px !important",
            fontWeight: "600",
          }}
        >
          : You can add shop image later
        </Typography>
      </Box>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          margin: "2rem",
          position: "fixed",
          bottom: 0,
          width: "82%",
        }}
      >
        <div className="next-btn">
          <FaSave style={{ fontSize: "1.5rem" }} onClick={handleAdd} />
        </div>
      </div>
      {openModal ? (
        <ImageRemovalModal open={openModal} handleClose={handleClose} />
      ) : null}
      {openError ? (
        <ErrorModal open={openError} handleClose={handleErrorModal} />
      ) : null}
      {openSucces ? (
        <SuccessModal
          open={openSucces}
          handleClose={handleSuccessModal}
          title="Ok"
          message="Shop image edited successfully"
        />
      ) : null}
      {uploadModal ? (
        <UploadModal open={uploadModal} handleClose={handleCloseUpload} />
      ) : null}
    </Fragment>
  );
}

export default EditShopImage;
