import React, { Fragment, useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory, useParams } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
import "../EditBusiness.css";
import useBusinessQuery from "../../../../../store/Bussiness/useBusinessQuery";
import useImageQuery from "../../../../../store/Images/useImageQuerys";
import ImageRemovalModal from "../../Add business/ImageRemovalModal";
import theme from "../../../../../utils/theme";
import ErrorModal from "../../Add business/ErrorModal";
import SuccessModal from "../../Add business/SuccessModal";
import axios from "axios";
import UploadModal from "../../../../../components/UploadModal";
import S3 from "react-aws-s3";
import { Buffer } from "buffer";
window.Buffer = Buffer;

const EditCoverPhoto = () => {
  const history = useHistory();
  const { id } = useParams();

  const [cover, setCover] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [imageId, setImageId] = useState("");
  const [plan, setPlan] = useState({});
  const [coverPhoto, setCoverPhoto] = useState(false);
  const [openSucces, setOpenSucces] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [message, setMessage] = useState("");

  let accessToken = localStorage.getItem("accessToken");
  const envConfig = process.env;

  const config = {
    bucketName: envConfig.REACT_APP_S3_BUCKET,
    region: envConfig.REACT_APP_S3_REGION,
    dirName: "master-click",
    accessKeyId: envConfig.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: envConfig.REACT_APP_S3_SECRET_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);

  const { data: subscriptionDetail, isSuccess: businessSuccess } =
    useBusinessQuery.Get_my_currentplan(id);

  const { data: getCover, isSuccess: coverSuccess } =
    useImageQuery.Get_cover(id);

  const {
    mutateAsync: addCover,
    isSuccess: success,
    isError: addIsError,
    error: addError,
  } = useImageQuery.Add_cover();

  const { mutateAsync: deleteCover } = useImageQuery.Del_cover();

  const handleCoverImage = async (e) => {
    try {
      let file = null;
      
      // If we have an event with files (from a standard input)
      if (e && e.target && e.target.files) {
        if (e.target.files.length === 0) {
          return;
        }
        file = e.target.files[0];
      }
      // Otherwise use either Flutter channel or create a file input
      else {
        // Flutter file channel approach
        if (window.FileChannel) {
          // Set up a callback to receive file data
          file = await new Promise((resolve, reject) => {
            // This part still needs a Promise since we're setting up a callback
            window.fileCallback = (fileData) => {
              console.log('Raw fileData received:', fileData);
              
              // Handle different possible data formats
              try {
                // Case 1: If fileData is an object with structured properties
                if (typeof fileData === 'object' && fileData !== null) {
                  const { fileName, fileBase64, fileSize, fileType } = fileData;
                  console.log('Structured data detected:', { fileName, fileSize, fileType });
                  
                  if (fileBase64) {
                    // Process base64 data
                    let base64Content = fileBase64;
                    if (fileBase64.includes('base64,')) {
                      base64Content = fileBase64.split('base64,')[1];
                    }
                    
                    // Create blob and file
                    const byteCharacters = atob(base64Content);
                    const byteArrays = [];
                    
                    for (let i = 0; i < byteCharacters.length; i += 1024) {
                      const slice = byteCharacters.slice(i, i + 1024);
                      const byteNumbers = new Uint8Array(slice.length);
                      
                      for (let j = 0; j < slice.length; j++) {
                        byteNumbers[j] = slice.charCodeAt(j);
                      }
                      
                      byteArrays.push(byteNumbers);
                    }
                    
                    const blob = new Blob(byteArrays, { type: fileType || 'image/jpeg' });
                    const fileObject = new File([blob], fileName || `image_${Date.now()}.jpg`, { type: fileType || 'image/jpeg' });
                    
                    console.log('Created File object from structured data');
                    return resolve(fileObject);
                  }
                }
                
                // Case 2: If fileData is a string containing base64 data
                if (typeof fileData === 'string') {
                  console.log('String data detected, length:', fileData.length);
                  
                  // If it's base64 format
                  if (fileData.includes('base64')) {
                    const matches = fileData.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
                    
                    if (matches && matches.length === 3) {
                      const mimeType = matches[1];
                      const base64Data = matches[2];
                      
                      // Process base64 data
                      const byteCharacters = atob(base64Data);
                      const byteArrays = [];
                      
                      for (let i = 0; i < byteCharacters.length; i += 1024) {
                        const slice = byteCharacters.slice(i, i + 1024);
                        const byteNumbers = new Uint8Array(slice.length);
                        
                        for (let j = 0; j < slice.length; j++) {
                          byteNumbers[j] = slice.charCodeAt(j);
                        }
                        
                        byteArrays.push(byteNumbers);
                      }
                      
                      const blob = new Blob(byteArrays, { type: mimeType });
                      const fileObject = new File([blob], `image_${Date.now()}.${mimeType.split('/')[1] || 'jpg'}`, { type: mimeType });
                      
                      console.log('Created File object from base64 string');
                      return resolve(fileObject);
                    }
                  }
                  
                  // If it's comma-separated format
                  const parts = fileData.split(',');
                  if (parts.length >= 3) {
                    const [path, name, size] = parts;
                    console.log('Comma-separated data detected:', { path, name, size });
                    // Here we don't have actual file content, just returning the raw data
                    return resolve(fileData);
                  }
                  
                  // If it's just raw base64 without prefix
                  try {
                    // Assume it might be raw base64
                    const byteCharacters = atob(fileData);
                    const byteArrays = [];
                    
                    for (let i = 0; i < byteCharacters.length; i += 1024) {
                      const slice = byteCharacters.slice(i, i + 1024);
                      const byteNumbers = new Uint8Array(slice.length);
                      
                      for (let j = 0; j < slice.length; j++) {
                        byteNumbers[j] = slice.charCodeAt(j);
                      }
                      
                      byteArrays.push(byteNumbers);
                    }
                    
                    const blob = new Blob(byteArrays, { type: 'image/jpeg' });
                    const fileObject = new File([blob], `image_${Date.now()}.jpg`, { type: 'image/jpeg' });
                    
                    console.log('Created File object from raw base64');
                    return resolve(fileObject);
                  } catch (e) {
                    console.log('Not valid base64 data');
                  }
                }
                
                // Fall back to just returning whatever we got
                console.log('Using raw data as fallback');
                return resolve(fileData);
              } catch (error) {
                console.error('Error processing file data:', error);
                return reject(error);
              }
            };
            
            console.log('Requesting file from Flutter...');
            try {
              window.FileChannel.postMessage('chooseFile');
            } catch (err) {
              console.error('Error calling FileChannel:', err);
              reject(err);
            }
          });
        }
        // Fallback to standard file input approach
        else {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = 'image/*';
          
          // We need to wrap this in a Promise because file selection is event-based
          file = await new Promise((resolve, reject) => {
            input.onchange = (event) => {
              if (event.target.files && event.target.files.length > 0) {
                const selectedFile = event.target.files[0];
                console.log('Selected file:', selectedFile);
                resolve(selectedFile);
              } else {
                reject(new Error('No file selected'));
              }
            };
            
            input.click();
          });
        }
      }
      
      if (file) {
        setUploadModal(true);
        const data = await ReactS3Client.uploadFile(file);
        const url = data.location;
        try {
          await addCover({
            id,
            cover_image: url,
            is_active: true,
          });
        } catch (error) {
          handleCloseUpload();
        }
        setCoverPhoto(false);
      }
    } catch (error) {
      console.error('Error handling photo:', error);
      // You might want to set an error state or notify the user
      handleCloseUpload(); // Close the modal even on error
    }
  }

  //   const formData = new FormData();
  //   formData.append("image", e.target.files[0]);

  //   const fileName = e.target.files[0]?.name;
  //   const fileExtension = fileName?.split(".")?.pop()?.toLowerCase();

  //   let contentType = "image/jpeg";
  //   if (fileExtension && fileExtension == "png") {
  //     contentType = "image/png";
  //   } else if (fileExtension && fileExtension == "svg") {
  //     return;
  //   } else if (
  //     (fileExtension && fileExtension == "jpg") ||
  //     (fileExtension && fileExtension == "jpeg")
  //   ) {
  //     contentType = "image/jpeg";
  //   }

  //   try {
  //     setUploadModal(true);

  //     const response = await axios.post(
  //       "https://dev2.enfono.com/api_master_mart/api/v1/common/image_coverter/",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": contentType,
  //         },
  //       }
  //     );
  //     if (
  //       response &&
  //       response?.data &&
  //       response?.data?.data &&
  //       response?.data?.status == "ok"
  //     ) {
  //       try {
  //         await addCover({
  //           id,
  //           cover_image: response?.data?.data,
  //           is_active: true,
  //         });
  //       } catch (error) {}
  //     }
  //   } catch (error) {
  //     handleCloseUpload();
  //     setOpenError(true);
  //   }
  //   setCoverPhoto(false);
  // };

  const handleCloseUpload = () => {
    setUploadModal(false);
  };

  const handleClose = async (value) => {
    if (value) {
      try {
        await deleteCover({
          businessId: id,
          id: imageId,
        });
      } catch (error) { }
    }
    setOpenModal(false);
  };

  const handleNext = () => {
    setOpenSucces(true);
  };

  const handleErrorModal = () => {
    setMessage("");
    setOpenError(false);
  };

  const handleSuccessModal = () => {
    if (plan.catalogue_images) {
      history.push(`/edit-catelogueimages/${id}`);
    } else {
      history.push("/my-list");
    }
    setOpenSucces(false);
  };

  useEffect(() => {
    if (coverSuccess && getCover) {
      setCover([...getCover.data.results]);
      handleCloseUpload();
    }
  }, [coverSuccess, getCover]);

  useEffect(() => {
    if (businessSuccess && subscriptionDetail) {
      setPlan(subscriptionDetail?.data?.selected_plan?.subscription_features);
    }
  }, [businessSuccess]);

  useEffect(() => {
    handleCloseUpload();
    if (addError && addIsError) {
      setMessage(addError?.data?.message);
      setOpenError(true);
    }
  }, [addError, success, addIsError]);

  return (
    <Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
          position: "fixed",
          top: 0,
          zIndex: "100",
        }}
      >
        <CloseIcon
          onClick={() => history.push("/my-list")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>Edit Cover Photo</p>
      </div>

      <div
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {cover.map((item, i) => (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              top: "20px",
            }}
            key={i}
          >
            {" "}
            <img
              src={item.cover_image}
              width={"92%"}
              height={"224px"}
              alt={item.business}
              style={{
                borderRadius: "20px",
                border: "4px solid rgba(22, 107, 142, 1)",
                marginBottom: "10px",
              }}
            />
            <Button
              className="delete-btn"
              onClick={() => {
                setImageId(item.id);
                setOpenModal(true);
              }}
            >
              <RiDeleteBin5Line />
            </Button>
          </div>
        ))}
      </div>
      {cover.length == 0 || coverPhoto ? (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          onClick={handleCoverImage}
        >
          {/* <input
            style={{
              display: "none",
            }}
            type="file"
            id="file"
          /> */}
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "#677eff",
              width: "92%",
              height: "3rem",
              border: "none",
              color: "info",
              backgroundColor: "#fff",
              border: "solid 3px #166B8E",
              borderRadius: "35px",
              padding: "8rem",
            }}
            htmlFor="file"
          >
            <span
              style={{
                width: "100%",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src="/images/img-icon.png" />
              <p style={{ color: "#2182B1" }}>
                Add Cover Image for your business
              </p>
            </span>
          </label>
        </div>
      ) : null}

      {cover.length !== plan?.cover_images ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "2rem",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: "12px",
              height: "3rem",
            }}
            onClick={() => setCoverPhoto(true)}
          >
            Add {plan?.cover_images - cover.length} more images
          </Button>
        </div>
      ) : null}

      <Button
        startIcon={<BsArrowRight />}
        onClick={handleNext}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "12px",
          height: "3rem",
          position: "fixed",
          bottom: "25px",
          right: "15px",
          minWidth: "0px",
          width: "50px",
          height: "50px",
          color: "white",
        }}
      ></Button>
      {openModal ? (
        <ImageRemovalModal open={openModal} handleClose={handleClose} />
      ) : null}
      {openError ? (
        <ErrorModal
          open={openError}
          handleClose={handleErrorModal}
          message={message}
        />
      ) : null}
      {openSucces ? (
        <SuccessModal
          open={openSucces}
          handleClose={handleSuccessModal}
          title="Ok"
          message="Cover photo edited successfully"
        />
      ) : null}
      {uploadModal ? (
        <UploadModal open={uploadModal} handleClose={handleCloseUpload} />
      ) : null}
    </Fragment>
  );
}

export default EditCoverPhoto;
